<template>
  <div class="Sprint800">
    <div class="banner">
      <div class="textBox">
        <div class="title">Sprint 800</div>
        <div class="smallTitle">Desktop Thermal Printer</div>
      </div>
    </div>
    <div class="Features">
      <div class="top">
        <ul>
          <li>
            <i></i>
            <div>
              Electronic one-touch and emergency manual lid opening design
            </div>
          </li>
          <li>
            <i></i>
            <div>Super compact footprint (127×127×127mm)</div>
          </li>
          <li>
            <i></i>
            <div>
              250mm/s high printing speed with low noise and overheat protection
              for longer lifespan
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <img src="../../../assets/Sprint800配图/PC/展示图2.png" alt="" />
      </div>
    </div>
    <div class="video">
      <video
        src="../../../assets/Sprint800配图/Compax_Sprint_800_Video.mp4"
        muted
        autoplay
        loop
        webkit-playsinline
        playsinline
      ></video>
    </div>
    <div class="product">
      <img src="../../../assets/Sprint800配图/移动/展示图.png" alt="" />
    </div>
    <div class="Agility">
      <div>
        <div class="titleBox">
          <div class="title">Sprint 800</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">Printing specifications</div>
              <div class="text">Printing Width: 72mm/48mm</div>
              <div class="text">Paper Roll Width: 58/80±0.5mm</div>
              <div class="text">Paper Roll Diameter: Outer diameter≤φ80mm</div>
              <div class="text">Printing Thickness: 0.056mm-0.09mm</div>
              <div class="text">
                Print Density: 576 dots per line (adjustable via commands)
              </div>
              <div class="text">Printing Speed: 250 mm/s</div>
              <div class="text">Print Head Life: 150 kilometers</div>
            </div>
            <div class="itemB">
              <div class="title">Interface Type</div>
              <div class="text">USB + COM (RS-232) + LAN (RJ-45)</div>
              <div class="text">Cash Drawer (Optional: Wi-Fi/Bluetooth)</div>
            </div>
            <div class="itemB">
              <div class="title">Buffering</div>
              <div class="text">NV FLASH Buffer: 256 KB</div>
              <div class="text">Input Buffer: 2048 Bytes</div>
              <div class="text">Cache: 8 MB</div>
            </div>
            <div class="itemB">
              <div class="title">Paper Feed Direction</div>
              <div class="text">Top Feeding / Front Feeding</div>
            </div>
            <div class="itemA">
              <div class="title">Font</div>
              <div class="text">
                Line spacing: 3.75mm (adjustable via commands)
              </div>
              <div class="text">Characters per line:</div>
              <div class="text">
                80 paper: Font A - 42 columns or 48 columns /
              </div>
              <div class="text">Font B - 56 columns or 64 columns /</div>
              <div class="text">
                Simplified / Traditional Chinese - 21 columns or 24 columns
              </div>
              <div class="text">Character size:</div>
              <div class="text">FontA: 1.5x3.0 (12x24 dots)</div>
              <div class="text">FontB: 1.1x2.1 (9x17 dots)</div>
              <div class="text">
                Simplified / Traditional Chinese: 3.0x3.0 (24x24 dots)
              </div>
            </div>
            <div class="itemB">
              <div class="title">Emulation</div>
              <div class="text">Print Command: Compatible with ESC/POS</div>
              <div class="text">Driver: Windows/OPOS</div>
            </div>
            <div class="itemB">
              <div class="title">Barcode Type</div>
              <div class="text">
                1D: UPCA/UPCE/JAN13(EAN13)/JAN8(EAN8)/CODE39/ITF/
              </div>
              <div class="text">CODABAR/CODE93/CODE128</div>
              <div class="text">2D: QR Code、PDF 417</div>
            </div>
            <div class="itemB">
              <div class="title">Auto Cutter</div>
              <div class="text">Automatic Cutter Blade: Half-Cut</div>
              <div class="text">Cutter Blade Life: 1.5 million times</div>
            </div>
            <div class="itemA">
              <div class="title">Environment</div>
              <div class="text">Operating Environment:</div>
              <div class="text">Temperature: 0°C to 40°C</div>
              <div class="text">Humidity: 30% to 90% RH (non-condensing)</div>
              <div class="text">Storage Environment:</div>
              <div class="text">Temperature: -20°C to 55°C</div>
              <div class="text">Humidity: 20% to 93% RH (non-condensing)</div>
            </div>
            <div class="itemB">
              <div class="title">Printing method</div>
              <div class="text">Line thermal printing</div>
            </div>
            <div class="itemB">
              <div class="title">Support</div>
              <div class="text">OS: Windows/Linux/Android/iOS/Mac</div>
              <div class="text">SDK: Android/iOS/WeChat Mini Program</div>
            </div>
            <div class="itemB">
              <div class="title">Power Supply</div>
              <div class="text">Power Input: DC 24V/2.5A</div>
              <div class="text">Cash Drawer Output: DC 24V/1A</div>
            </div>
          </div>
        </div>
        <!-- <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">9520</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon"><i class="el-icon-bottom"></i></div>
            </div>
            <div class="itemB">
              <div class="title">9520 User Manual</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon"><i class="el-icon-bottom"></i></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.Sprint800 {
  background: #000;
  .banner {
    position: relative;
    height: 278.69px;
    background: url('../../../assets/Sprint800配图/移动/首页.png') no-repeat
      center;
    background-size: cover;
    .textBox {
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: center;
      .title {
        text-align: center;
        color: rgba(0, 92, 255, 1);
        font-size: 26px;
        font-weight: 700;
        line-height: 37.65px;
      }
      .smallTitle {
        text-align: center;
        margin: 0px 0px 11px 0px;
        color: #fff;
        font-size: 12px;
        line-height: 17.38px;
      }
    }
  }
  .Features {
    margin-top: 78px;
    background: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    .top {
      .title {
        color: #fff;
        text-align: center;
        margin: 7px 0px;
        font-size: 10px;
        font-weight: 600;
        line-height: 40px;
      }
      ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        li {
          color: #fff;
          width: 265px;
          display: flex;
          margin-bottom: 25px;
          line-height: 11.58px;
          font-size: 8px;
          &:last-child {
            margin-bottom: 0px;
          }
          i {
            margin: 0px 7px 0px 0px;
            width: 0;
            height: 0;
            font-size: 0;
            line-height: 0;
            border-top: 6px solid transparent;
            border-left: 12px solid rgba(0, 92, 255, 1);
            border-bottom: 6px solid transparent;
          }
        }
      }
    }
    .bottom {
      width: 294px;
      height: 232px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .video {
    height: 219.42px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .product {
    display: flex;
    justify-content: center;
    background: #000;
    img {
      height: 921px;
    }
  }
  .Agility {
    background: rgba(0, 0, 0, 1);
    padding: 27.46px 15px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 28.96px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      padding-bottom: 6px;
      border-bottom: 2px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -2px;
        content: '';
        width: 175px;
        height: 0px;
        border-bottom: 2px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      padding: 0px 6px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 357.5px;
        height: 270px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 359.06px;
        height: 140px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
